@import '../../../../scss/theme-bootstrap';

.gnav-promo-banner {
  display: block;
  height: $gnav-promo-mb-height;
  min-height: $gnav-promo-mb-height;
  overflow: hidden;
  @media #{$cr19-large-up} {
    height: $gnav-promo-pc-height;
    min-height: $gnav-promo-pc-height;
  }
  &__carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    transform: none;
    top: 0;
    width: 100%;
    height: 100%;
    @media #{$cr19-large-up} {
      top: 50%;
      transform: translateY(-50%);
    }
    &-item {
      display: none;
      .not-initialized &:first-child,
      &.item-active {
        display: block;
      }
      .fade & {
        animation-name: fade;
        animation-duration: 1.5s;
      }
    }
    &-content {
      overflow: hidden;
      width: 100%;
      @media #{$cr19-large-up} {
        overflow: unset;
      }
      &-offer-text {
        @include text-body-text--small;
        display: inline;
        & + .gnav-promo-banner__carousel-content-link {
          text-decoration: underline;
        }
        .gnav-promo-bar-black & {
          color: $cr19-text-white;
        }
        p {
          display: inline;
          line-height: inherit;
        }
      }
      &-link {
        @include text-body-text--small;
        .gnav-promo-bar-black & {
          color: $cr19-text-white;
          text-decoration-color: $cr19-text-white;
        }
        .gnav-promo-bar-green & {
          text-decoration-color: $color-black;
        }
      }
    }
    &-btn {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
      button {
        border: 1px solid transparent;
        background-color: transparent;
      }
      .icon {
        width: 15px;
        height: 15px;
        .gnav-promo-bar-black & {
          color: $cr19-text-white;
          fill: $cr19-text-white;
        }
        .gnav-promo-bar-green & {
          color: $color-black;
          fill: $color-black;
        }
      }
    }
    &-arrow-prev {
      @media #{$cr19-large-up} {
        #{$ldirection}: 0;
        transform: rotate(180deg);
        [dir='rtl'] & {
          transform: rotate(0deg);
        }
      }
    }
    &-arrow-next {
      @media #{$cr19-large-up} {
        margin-top: 2px;
        #{$rdirection}: 0;
        transform: rotate(0deg);
        [dir='rtl'] & {
          transform: rotate(180deg);
        }
      }
    }
  }
  &.gnav-promo-bar-black {
    background-color: $cr19-bg-black;
  }
  &.gnav-promo-bar-green {
    background-color: $color-cl-green-bg;
  }
}
